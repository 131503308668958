import { BodySizeContext } from "app/(platform)/_contexts/BodySizeContext";
import useSize from "app/_hooks/useSize";
import { M } from "app/_types/Schema";
import { useContext, useEffect, useMemo } from "react";
import { apiSectionAsset } from "../_api/sectionAsset";
import { isSectionAssetExpired } from "./isSectionAssetExpired";

/* 
TODO: 

Take in a SectionAsset object and return the asset and asset data (w/h)
Handing one asset
Refresh if necesary.

*/

export function useDirectusAsset({ section_asset, vh, vw }: { section_asset: M.SectionAsset | undefined | null; vh: number; vw: number }) {
   const { bodyWidth, bodyHeight } = useContext(BodySizeContext);

   const { width, height } = useMemo(() => {
      if (section_asset && bodyHeight && bodyWidth) {
         const width = section_asset?.section_asset_width || bodyWidth;
         const height = section_asset?.section_asset_height || bodyHeight * 0.8;
         return { width, height };
      }
      return { width: undefined, height: undefined };
   }, [section_asset]);

   const { elementWidth: assetWidth, elementHeight: assetHeight } = useSize({ mediaWidth: width, mediaHeight: height, bodyWidth, bodyHeight, fullScreenElement: false, vh, vw });

   const { url, refresh } = useMemo(() => {
      if (section_asset) {
         const refresh = isSectionAssetExpired({ section_asset });

         const url = refresh ? undefined : section_asset?.section_asset_url || undefined;
         return { url, refresh };
      }
      return { url: undefined, refresh: undefined };
   }, [section_asset]);

   const [refreshAsset] = apiSectionAsset.useRefreshSectionAssetMutation();

   useEffect(() => {
      if (refresh && section_asset?.id) {
         refreshAsset({ id: section_asset?.id });
      }
   }, [refresh, section_asset?.id]);

   return {
      assetWidth,
      refresh,
      assetHeight,
      url
   };
}
