// Avatar.tsx
import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiAvatar } from "app/(platform)/profile/_api/apiAvatar";
import { useDirectusAsset } from "app/(platform)/workspaces/[campaign_id]/(editor)/_hooks/useDirectusAsset";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import NextImage from "next/image";
import React, { useEffect } from "react";

interface AvatarProps extends VariantProps<typeof avatarClasses> {
   src?: string;
   initials: string | undefined;
   className?: string;
   userId: string | undefined;
   testId?: string;
}

const avatarClasses = cva("flex items-center justify-center rounded-full overflow-hidden", {
   variants: {
      size: {
         sm: "h-10 w-10 text-xl",
         md: "h-16 w-16 text-2xl",
         lg: "h-24 w-24 text-4xl"
      }
   }
});

export const Avatar: React.FC<AvatarProps> = ({ size = "sm", src, initials, className = "", userId, testId }) => {
   const combinedClasses = avatarClasses({ size }) + ` ${className}`;

   const avatarDimentions = 300;
   const { authenticatedRole, publicRole } = useAuthenticatedPublicRoles();
   const role = authenticatedRole || publicRole;
   const { data: sectionAsset, refetch } = apiAvatar.useGetOneAvatarQuery(
      {
         role: role,
         query: {
            filter: {
               section_asset_section: {
                  _and: [
                     { status: { _eq: "published" } },
                     {
                        section_dashboard_control: {
                           dashboard_control_directus_users: {
                              _eq: userId
                           }
                        }
                     }
                  ]
               }
            }
         }
      },
      { skip: !userId || !role || !!src }
   );

   ILog.v("Avatar sectionAsset", { id: sectionAsset?.id });

   const { url, refresh } = useDirectusAsset({ section_asset: sectionAsset, vh: 30, vw: 50 });
   useEffect(() => {
      if (refresh) {
         refetch();
      }
   }, [refresh, refetch]);

   const finalSrc = src || url;
   return (
      <div className={combinedClasses} data-testid={testId}>
         {finalSrc ? (
            <NextImage
               // style={{ animationPlayState: "paused", animation: "none", motion: "paused" }}
               src={finalSrc}
               // onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
               //    const el = e.currentTarget;
               //    if (el === null) return;

               //    ILog.v("Avatar Image Loaded", { e });
               //    // const img = new Image();

               //    // img.onload = function (event) {
               //    //    el.previousElementSibling.getContext("2d").drawImage(img, 0, 0);
               //    //    el.previousElementSibling?.getAnimations().forEach((animation) => {
               //    //       animation.pause();
               //    //    });
               //    // };
               //    // img.src = el.getAttribute("data-src");

               //    // el.onmouseover = function (event) {
               //    //    event.target.src = event.target.getAttribute("data-src");
               //    // };
               //    // el.onmouseout = function (event) {
               //    //    event.target.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
               //    // };
               // }}
               onError={(e) => {
                  ILog.e("Avatar Image Error", { e });
               }}
               data-testid={`${testId}-avatar-image`}
               alt={`${initials}`}
               width={avatarDimentions}
               height={avatarDimentions}
               className="object-cover w-full h-full [animation-play-state: paused]"
            />
         ) : (
            <div data-testid={`${testId}-avatar-initials`} className="flex items-center justify-center w-full h-full text-gray-900 bg-gray-200">
               {initials}
            </div>
         )}
      </div>
   );
};
function Fallback(props: { name: string }) {
   const { name } = props;

   const parsedName = name?.split(" ");
   const firstName = parsedName[0];
   const lastName = parsedName[1];
   const initials = (firstName?.charAt(0)?.toUpperCase() || "") + (lastName?.charAt(0)?.toUpperCase() || "");
   return <span>{initials}</span>;
}
