import { Query } from "@directus/sdk";
import { refresh } from "app/(platform)/(authentication)/_actions/refresh";
import getAuthenticatedSessionClientside from "app/(platform)/(authentication)/_helpers/getAuthenticatedSessionClientside";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import refreshAssetURL from "app/(platform)/_actions/refreshAsset";
import transformImage from "app/(platform)/_actions/transformImage";
import uploadUserAsset from "app/(platform)/_actions/uploadUserAsset";
import { apiSectionAsset } from "app/(platform)/workspaces/[campaign_id]/(editor)/_api/sectionAsset";
import { apiSections } from "app/(platform)/workspaces/[campaign_id]/(editor)/_api/step";
import { putS3File } from "app/_lib/directus/assets";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "app/_services/redux/api/_helpers/rtkHelpers";
import { NextServerAction } from "app/_services/redux/api/apiNext";
import { apiService } from "app/_services/redux/api/apiService";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { apiDashboardControl } from "./dashboardControl";

export const apiAvatar = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getOneAvatar: builder.query<
         M.SectionAsset | null,
         {
            query: Query<M.CustomDirectusTypes, M.SectionAsset[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               // const user = await api.dispatch(apiDirectusUsers.endpoints.getOneDirectusUsers.initiate({ token, role: undefined, query: arg.query })).unwrap();
               // const control = await store
               //    .dispatch(
               //       apiDashboardControl.endpoints.getOneDashboardControl.initiate({
               //          token,
               //          role: undefined,
               //          query: {
               //             filter: {
               //                dashboard_control_directus_users: {
               //                   _eq: user.id
               //                }
               //             }
               //          }
               //       })
               //    )
               //    .unwrap();
               const sectionAsset = await api
                  .dispatch(
                     apiSectionAsset.endpoints.getOneSectionAsset.initiate({
                        token,
                        role: undefined,
                        query: arg.query

                        // {
                        //    filter: {
                        //       section_asset_section: {
                        //          _and: [
                        //             {
                        //                section_dashboard_control: {
                        //                                  dashboard_control_directus_users: {
                        //                                     _eq: user.id
                        //                                  }
                        //                }
                        //             },
                        //             // { section_dashboard_version: { _eq: control.dashboard_control_dashboard_version[0] } }
                        //             // { section_section_avatar: { _is_null: false } }
                        //          ]
                        //       }
                        //    }
                        // }
                     })
                  )
                  .unwrap();

               return { data: sectionAsset };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiAvatar_getOneAvatar" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DIRECTUS_USERS, includeList: true, idKey: "section_asset_directus_users" }]
            })
      }),

      stageAvatar: builder.mutation<M.SectionAsset, { payload: { localFile: File } }>({
         queryFn: async (arg, api) => {
            try {
               ILog.v("stageAvatar", { arg });
               const authenticatedSession = getAuthenticatedSessionClientside();

               if (!authenticatedSession) throw new Error(TAGS.UNKNOWN_ERROR);
               const {
                  access_token: token,
                  user: { id: userId }
               } = authenticatedSession;
               const control = await api.dispatch(apiDashboardControl.endpoints.getMyDashboardControl.initiate({})).unwrap();
               // If you get this error, it is becasue the user's dashboard control was deleted. This should never happen!!
               // On userCreate, we make a singleton dashboard control for the user. It should never be deleted. If it is, you will need to manually create a new one.
               if (!control) throw new Error("DASHBOARD_CONTROL_ERROR");
               const section = await api
                  .dispatch(
                     apiSections.endpoints.createSection.initiate({
                        role: undefined,
                        token,
                        payload: {
                           status: "draft",
                           section_directus_users: userId,
                           section_dashboard_version: control.dashboard_control_dashboard_version[0],
                           section_dashboard_control: control.id,

                           section_run: null,
                           section_section_asset: [],
                           section_section_attempt: [],
                           section_section_avatar: {
                              //@ts-expect-error
                              create: [
                                 {
                                    status: "draft"
                                 }
                              ]
                           }
                        }
                     })
                  )
                  .unwrap();
               // let transformParams = await arg.payload.localFile.arrayBuffer();
               let ab = await arg.payload.localFile.arrayBuffer();

               let transformParams = Buffer.from(ab).toString("base64");
               ILog.v("transformRes1", { data: arg.payload.localFile, transformParams });
               const transformRes = await transformImage({ data: transformParams }).then(async (transform: NextServerAction<{ buffer: string }>) => {
                  if (transform.status === 200) {
                     return transform;
                  } else {
                     throw new Error("UNKNOWN_ERROR");
                  }
               });
               // ILog.v("transformRes2", { transformRes });
               if (!transformRes?.data?.buffer) throw new Error("TRANSFORM_ERROR");
               const finalBuffer = Buffer.from(transformRes.data.buffer, "base64");
               // const finalFile = new File([finalBuffer], arg.payload.localFile.name, { type: "image/png" });
               const finalFile = new File([finalBuffer], arg.payload.localFile.name, { type: "image/png" });
               ILog.v("transformRes3", { finalBuffer, finalFile });
               const createS3AssetRes = await uploadUserAsset({ assetType: "image/png", targetUser: userId, targetSection: section?.id, fileName: finalFile.name }).then(
                  async (upload: NextServerAction<{ upload: { url: string; expires_at: string }; asset: M.SectionAsset }>) => {
                     if (upload.status === 200) {
                        return upload;
                     } else {
                        throw new Error("UNKNOWN_ERROR");
                     }
                  }
               );
               ILog.v("createS3AssetRes", { createS3AssetRes });

               const resd = await putS3File({ endpoint: createS3AssetRes?.data?.upload.url!, data: finalFile, contentType: "image/png" }).then(async (s3: any) => {
                  if (s3.status === 200) {
                     return s3;
                  } else {
                     throw new Error("UNKNOWN_ERROR");
                  }
               });
               ILog.v("resd", { resd });
               const resE = await refreshAssetURL({ sectionAssetID: createS3AssetRes?.data?.asset.id! }).then(async (refresh: any) => {
                  if (refresh.status === 200) {
                     return refresh;
                  } else {
                     throw new Error("UNKNOWN_ERROR");
                  }
               });
               ILog.v("resE", {
                  resE,
                  payload: {
                     section_section_asset: [createS3AssetRes?.data?.asset.id]
                  }
               });
               const newSection = await api
                  .dispatch(
                     apiSections.endpoints.updateSectionById.initiate({
                        role: undefined,
                        token,
                        id: section.id,
                        payload: {
                           section_section_asset: [createS3AssetRes.data?.asset.id]
                        }
                     })
                  )
                  .unwrap();

               ILog.v("newSection", { newSection });

               const sectionAsset = await api
                  .dispatch(
                     apiSectionAsset.endpoints.getOneSectionAsset.initiate({
                        role: undefined,
                        token,
                        query: {
                           filter: {
                              section_asset_section: {
                                 _eq: section?.id
                              }
                           }
                        }
                     })
                  )
                  .unwrap();
               if (!sectionAsset) throw new Error("SECTION_ASSET_ERROR");
               ILog.v("sectionAsset", { sectionAsset });

               return { data: sectionAsset };
            } catch (error: any) {
               ILog.v("stageAvatar", { error });
               return handleRTKError({ error, endpoint: "stageAvatar" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DIRECTUS_USERS, includeList: false, idKey: "section_asset_directus_users" }],
               resultTags: result && [
                  { type: TAGS.SECTION, id: result.id },
                  { type: TAGS.DIRECTUS_USERS, id: "LIST" },
                  { type: TAGS.AUTHENTICATED_SESSION, id: "SINGLETON" }
               ]
            })
      }),

      confirmAvatarChange: builder.mutation<M.Section, { id: string; oldSectionAsset: M.SectionAsset | undefined }>({
         queryFn: async (arg, api) => {
            try {
               const authenticatedSession = getAuthenticatedSessionClientside();

               if (!authenticatedSession) throw new Error(TAGS.AUTHENTICATED_ERROR);
               const {
                  access_token: token,
                  user: { id: userId }
               } = authenticatedSession;
               // if (arg.oldSectionAsset) {
               //    await store
               //       .dispatch(
               //          apiSectionAsset.endpoints.deleteSectionAssetById.initiate({
               //             role: undefined,
               //             token,
               //             id: arg.oldSectionAsset.id
               //          })
               //       )
               //       .unwrap();
               //    await api.dispatch(apiSections.endpoints.deleteSectionById.initiate({ role: undefined, token, id: arg.oldSectionAsset.section_asset_section as string })).unwrap();
               // }
               const newSection = await api
                  .dispatch(
                     apiSections.endpoints.updateSectionById.initiate({
                        role: undefined,
                        token,
                        id: arg?.id as string,
                        payload: {
                           status: "published"
                        }
                     })
                  )
                  .unwrap();
               if (!authenticatedSession?.refresh_token) throw new Error(TAGS.AUTHENTICATED_ERROR);
               await refresh(authenticatedSession?.refresh_token);

               return { data: newSection };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "confirmAvatarChange" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DIRECTUS_USERS, includeList: false, idKey: "section_asset_directus_users" }],
               resultTags: result && [
                  { type: TAGS.SECTION, id: result.id },
                  { type: TAGS.DIRECTUS_USERS, id: "LIST" },
                  { type: TAGS.AUTHENTICATED_SESSION, id: "SINGLETON" }
               ]
            })
      }),
      deleteAvatar: builder.mutation<null, { oldSectionAsset: M.SectionAsset | null | undefined }>({
         queryFn: async (arg, api) => {
            try {
               const authenticatedSession = getAuthenticatedSessionClientside();

               if (!authenticatedSession) throw new Error(TAGS.AUTHENTICATED_ERROR);
               const {
                  access_token: token,
                  user: { id: userId }
               } = authenticatedSession;
               ILog.v("deleteAvatar", { arg });
               if (arg.oldSectionAsset?.id) {
                  await api
                     .dispatch(
                        apiSectionAsset.endpoints.deleteSectionAssetById.initiate({
                           role: undefined,
                           token,
                           id: arg.oldSectionAsset.id
                        })
                     )
                     .unwrap();
                  if ((arg.oldSectionAsset.section_asset_section as M.Section)?.id) {
                     //@ts-expect-error
                     await api.dispatch(apiSections.endpoints.deleteSectionById.initiate({ role: undefined, token, id: arg.oldSectionAsset?.section_asset_section?.id! })).unwrap();
                  } else if (arg.oldSectionAsset.section_asset_section) {
                     await api.dispatch(apiSections.endpoints.deleteSectionById.initiate({ role: undefined, token, id: arg.oldSectionAsset.section_asset_section as string })).unwrap();
                  }
               }

               return { data: null };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "deleteAvatar" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result && [
                  { type: TAGS.DIRECTUS_USERS, id: "LIST" },
                  { type: TAGS.AUTHENTICATED_SESSION, id: "SINGLETON" }
               ]
            })
      })
   }),
   overrideExisting: true
});
