import axios from "axios";

export async function putS3File({ endpoint, data, contentType, setProgress }: { endpoint: string; data: any; contentType: string; setProgress?: (progress: number) => void }) {
   return await axios.put(endpoint, data, {
      headers: {
         "Content-Type": contentType
      },
      onUploadProgress: function (progressEvent) {
         if (setProgress && progressEvent?.loaded && progressEvent?.total) {
            setProgress(progressEvent.loaded / progressEvent.total);
         }
      }
   });
}
