export function calculateDimensions(heightShare: number, widthShare: number, trackRatio: number, fullScreenElement: boolean): { finalHeight: number; finalWidth: number } {
   if (heightShare > widthShare) {
      const finalHeight = fullScreenElement ? heightShare : widthShare / trackRatio;
      const finalWidth = fullScreenElement ? widthShare : finalHeight * trackRatio;
      return {
         finalHeight: Math.floor(finalHeight),
         finalWidth: Math.floor(finalWidth)
      };
   } else if (heightShare < widthShare) {
      const finalWidth = fullScreenElement ? widthShare : heightShare * trackRatio;
      const finalHeight = fullScreenElement ? heightShare : finalWidth / trackRatio;
      return {
         finalHeight: Math.floor(finalHeight),
         finalWidth: Math.floor(finalWidth)
      };
   } else {
      return {
         finalHeight: 100,
         finalWidth: 100
      };
   }
   
}