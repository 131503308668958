import { Query } from "@directus/sdk";
import getAuthenticatedSessionClientside from "app/(platform)/(authentication)/_helpers/getAuthenticatedSessionClientside";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { TAGS } from "app/_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "app/_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "app/_services/redux/api/apiService";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";

export const apiDashboardControl = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getMyDashboardControl: builder.query<M.DashboardControl | null, {}>({
         queryFn: async (arg, api) => {
            try {
               const authenticatedSession = getAuthenticatedSessionClientside();
               const res = await REST.DashboardControlService.getOne({
                  token: authenticatedSession?.access_token,
                  query: {
                     filter: {
                        dashboard_control_directus_users: {
                           _eq: authenticatedSession?.user.id
                        }
                     }
                  }
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getMyDashboardControl" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.ACTIVITY_CONTROL, includeList: false }]
            })
      }),
      getOneDashboardControl: builder.query<
         M.DashboardControl | null,
         {
            query?: Query<M.CustomDirectusTypes, M.DashboardControl[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });

               const res = await REST.DashboardControlService.getOne({
                  token: token,
                  query: arg.query,
                  id: arg.id
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiDashboardControl_getOne" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DASHBOARD_CONTROL, includeList: true }]
            })
      }),
      getManyDashboardControl: builder.query<
         M.DashboardControl[],
         {
            query: Query<M.CustomDirectusTypes, M.DashboardControl[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.DashboardControlService.getMany({
                  token: token,
                  query: arg.query
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyDashboardControl" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.ACTIVITY_CONTROL, includeList: true }]
            })
      }),
      updateDashboardControlById: builder.mutation<M.DashboardControl, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.DashboardControl> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.DashboardControlService.updateById({ token, id: arg.id, payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateDashboardControlById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.ACTIVITY_CONTROL, includeList: false }]
            })
      }),
      createDashboardControl: builder.mutation<M.DashboardControl, { role: PA.RoleQueries | undefined; token?: string | undefined; payload: M.IOmit<M.DashboardControl> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await REST.DashboardControlService.create({ token: token!, payload: arg.payload });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createDashboardControl" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.ACTIVITY_CONTROL, includeList: true }]
            })
      })
   }),
   overrideExisting: true
});
