import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { DateTime } from "luxon";

export function isSectionAssetExpired({ section_asset }: { section_asset: M.SectionAsset | undefined | null }) {
   if (section_asset) {
      const isExpired = section_asset?.section_asset_url_expires_at ? DateTime.now() > DateTime.fromISO(section_asset?.section_asset_url_expires_at, { zone: "UTC" }).minus({ days: 2 }) : undefined;
      const isMissingURL = !section_asset?.section_asset_url;
      const refresh = isExpired === true || isMissingURL;
      ILog.v("isSectionAssetExpired", { isExpired, isMissingURL, refresh });
      return refresh;
   }
   return true;
}
