import { calculateDimensions } from "app/(platform)/meeting-room/_helpers/calculateDimensions";
import { useMemo } from "react";

interface UseSizeProps {
   mediaWidth: number | null | undefined;
   mediaHeight: number | null | undefined;
   bodyWidth?: number;
   bodyHeight?: number;
   fullScreenElement?: boolean;
   vh?: number;
   vw?: number;
}
export default function useSize({ mediaHeight, mediaWidth, bodyWidth, bodyHeight, fullScreenElement, vh, vw }: UseSizeProps) {
   const { elementHeight, elementWidth } = useMemo(() => {
      const { finalHeight, finalWidth } = calculateFinalHeightAndWidth({ mediaWidth, mediaHeight, bodyWidth, bodyHeight, fullScreenElement, vh, vw });
      return {
         elementHeight: finalHeight,
         elementWidth: finalWidth
      };
   }, [vh, window.innerHeight, window.innerWidth, fullScreenElement, vw, screen.width, screen.height, mediaHeight, mediaWidth]);
   return {
      elementWidth,
      elementHeight
   };
}

export function calculateFinalHeightAndWidth({ mediaHeight, mediaWidth, bodyHeight, bodyWidth, fullScreenElement, vh, vw }: UseSizeProps) {
   if (!mediaWidth || !mediaHeight || fullScreenElement === undefined) {
      return {
         finalHeight: undefined,
         finalWidth: undefined
      };
   }
   const screenHeight = fullScreenElement ? screen.height : window.innerHeight;
   const screenWidth = fullScreenElement ? screen.width : window.innerWidth;

   const heightShare = fullScreenElement ? screenHeight * (Number("95") / 100) : screenHeight * (Number(vh) / 100);
   const widthShare = fullScreenElement ? screenWidth * (Number("100") / 100) : screenWidth * (Number(vw) / 100);

   const trackRatio = mediaWidth / mediaHeight;

   const proposedHeight = heightShare > mediaHeight ? mediaHeight : heightShare;
   const proposedWidth = widthShare > mediaWidth ? mediaWidth : widthShare;

   const { finalHeight, finalWidth } = calculateDimensions(proposedHeight, proposedWidth, trackRatio, fullScreenElement);
   return {
      finalHeight,
      finalWidth
   };
}
