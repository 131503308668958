import { Query } from "@directus/sdk";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import refreshAssetURL from "app/(platform)/_actions/refreshAsset";
import ILog from "app/_lib/global/Log";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { TAGS } from "../../../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../../../_services/redux/api/apiService";
export const apiSectionAsset = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getOneSectionAsset: builder.query<
         M.SectionAsset | null,
         {
            query?: Query<M.CustomDirectusTypes, M.SectionAsset[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });

               const res = await REST.SectionAssetService.getOne({
                  token: token,
                  query: arg.query,
                  id: arg.id
               });
               ILog.v("getOneSectionAsset", { res, token });

               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiSectionAsset_getOne" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.SECTION_ASSET, includeList: true }]
            })
      }),
      getManySectionAsset: builder.query<
         M.SectionAsset[],
         {
            query: Query<M.CustomDirectusTypes, M.SectionAsset[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.SectionAssetService.getMany({
                  token: token,
                  query: arg.query
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManySectionAsset" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.SECTION_ASSET, includeList: false }]
            })
      }),
      updateSectionAssetById: builder.mutation<M.SectionAsset, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.SectionAsset> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.SectionAssetService.updateById({ token, id: arg.id, payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateSectionAssetById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.SECTION_ASSET, includeList: false }]
            })
      }),
      refreshSectionAsset: builder.mutation<string, { id: string }>({
         queryFn: async (arg, api) => {
            try {
               // const authenticatedSession = getAuthenticatedSessionClientside();

               // if (!authenticatedSession) throw new Error(TAGS.UNKNOWN_ERROR);

               const res = await refreshAssetURL({ sectionAssetID: arg.id });
               return { data: "success" };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "refreshSectionAsset" });
            }
         },
         invalidatesTags: (result, error, query) =>
            //@ts-expect-error
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && query?.id && [{ type: TAGS.SECTION_ASSET, id: query.id }] })
      }),
      createSectionAsset: builder.mutation<M.SectionAsset, { role: PA.RoleQueries | undefined; token?: string | undefined; payload: M.IOmit<M.SectionAsset> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await REST.SectionAssetService.create({ token: token!, payload: arg.payload });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createSectionAsset" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.SECTION_ASSET, includeList: false }]
            })
      }),
      deleteSectionAssetById: builder.mutation<string, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await REST.SectionAssetService.deleteById({ token: token!, id: arg.id });
               return { data: "success" };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "deleteSectionAssetById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result
                  ? [
                       { type: TAGS.SECTION_ASSET, id: "LIST" },
                       { type: TAGS.DIRECTUS_USERS, id: "LIST" }
                    ]
                  : []
            })
      })
   }),
   overrideExisting: true
});
