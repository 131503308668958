import useAuthenticatedPublicRoles from "app/(platform)/(authentication)/_hooks/useAuthenticatedPublicRoles";
import { apiDirectusUsers } from "app/(platform)/@search/_api/directusUsers";
import { CampaignContext } from "app/(platform)/_contexts/CampaignContext";
import useAllCDURoleQueries from "app/(platform)/workspaces/[campaign_id]/_hooks/useAllCDUQueries";
import { Avatar } from "app/_components_v2/data-display/Avatar";
import { useFallback } from "app/_hooks/useFallback";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { VariantProps, cva } from "class-variance-authority";
import Link from "next/link";
import { useContext, useMemo } from "react";

interface UserCardProps extends VariantProps<typeof userCardClasses> {
   userId: string | undefined;
   withAvatar?: boolean;
   textFirstLastOnly?: boolean;
}
const userCardClasses = cva("flex flex-row gap-4 items-center justify-center rounded-full overflow-hidden", {
   variants: {
      size: {
         sm: "h-10 text-xl",
         md: "h-16 text-2xl",
         lg: "h-24 text-4xl"
      }
   }
});
function UserCard({ userId, size = "md", withAvatar = true, textFirstLastOnly }: UserCardProps) {
   const combinedClasses = userCardClasses({ size });
   const { campaignId } = useContext(CampaignContext);
   const { authenticatedRole, publicRole } = useAuthenticatedPublicRoles();
   const { connectionRole } = useAllCDURoleQueries({ campaignId });
   const role = useFallback([connectionRole, authenticatedRole, publicRole]);
   const { data: user } = apiDirectusUsers.useGetOneDirectusUsersQuery({ role, id: userId, query: { filter: { id: { _eq: userId } } } }, { skip: !userId || !role });
   // const fallbackName = `${first_name} ${last_name || ""}`;
   // const initials = fallbackName ? getInitials(fallbackName) : "";

   ILog.v("UserCard", { user, userId, role });
   const initials = useMemo(() => {
      if (user) {
         return getInitials(`${user.first_name} ${user.last_name}`);
      }
      return "";
   }, [user?.first_name, user?.last_name]);
   if (user) {
      if (textFirstLastOnly) {
         return `${user.first_name} ${user.last_name}`;
      }
      return (
         <div className={combinedClasses}>
            {withAvatar && <Avatar userId={userId} size={size} initials={initials} />}
            <FullName user={user} size={size} />
         </div>
      );
   }
   return null;
}
export const getInitials = (name: string) => {
   const [firstName, lastName] = name.split(" ");
   return `${firstName?.[0]?.toUpperCase() || ""}${lastName?.[0]?.toUpperCase() || ""}`;
};
interface FullNameProps extends VariantProps<typeof fullNameClasses> {
   user: M.DirectusUsers | undefined;
}
const fullNameClasses = cva("", {
   variants: {
      size: {
         sm: "text-xl",
         md: "text-2xl",
         lg: "text-4xl"
      }
   }
});
function FullName({ user, size = "md" }: FullNameProps) {
   const combinedClasses = fullNameClasses({ size }) + " hover:underline";
   if (user) {
      return <Link href={`/profile/${user.id}`} rel="noopener noreferrer" target="_blank" className={combinedClasses}>{`${user.first_name} ${user.last_name}`}</Link>;
   }
   return null;
}

UserCard.FullName = FullName;

export default UserCard;
